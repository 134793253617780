@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html {
  cursor: crosshair;
  max-width: 100%;
  overflow-x: hidden;
}

::-webkit-scrollbar {
    display: none;
}

::selection {
  background: #E00000;
}

.App {
  font-family: 'IG UI Beta v8', 'Inter', sans-serif;
  text-align: left;
  margin-top: 16vh;
  margin-left: 12%;
  margin-right: 12%;
  margin-bottom: 12vh;
  color: #000000;
}

.content {
  margin: auto;
  min-width: 280px;
  max-width: 1080px;
}

#container {
  position: relative;
}

#tomato-outline {
  position: absolute;
  width: 100px;
}

#tomato-filled {
  visibility: hidden;
  width: 100px;
}

h1 {
  font-family: 'RocGrotesk';
  font-size: 5.5em;
  line-height: 100%;
  margin-bottom: 5%;
}

h2 {
  font-size: 20px;
  font-family: 'RocGrotesk';
  line-height: 1em;
  margin-top: 5%;
  margin-bottom: -1%;
}

sub {
  color: #C0C0C0;
}

p {

}

a {
  color: #000000;
  text-decoration: none;
}

a:hover, .cv:hover {
  color: #E00000;
}

@media only screen and (max-width: 900px) {
  .App{
    margin-top: 8vh;
  }

  #tomato-outline, #tomato-filled {
    width: 60px;
  }

  .content {
    max-width: 100%;
  }

  h1{
    font-size: 3rem;
  }

  h2 {
    font-size: 20px;
  }

  p {

  }
}
