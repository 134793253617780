body {
  margin: 0;
  font-family: 'RocGrotesk', 'IG UI Beta v8', 'Inter', sans-serif;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* other formats include: 'woff2', 'truetype, 'opentype', 'embedded-opentype', and 'svg' */

@font-face {
  font-family: 'RocGrotesk';
  src: url(fonts/RocGrotesk-Medium.otf) format('opentype');
  font-weight: medium;
  font-style: normal;
}

@font-face {
  font-family: 'RocGrotesk';
  src: url(fonts/RocGrotesk-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'IG UI Beta v8';
  src: url(fonts/IGUIBetav8-Regular.otf) format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'IG UI Beta v8';
  src: url(fonts/IGUIBetav8-Bold.otf) format('opentype');
  font-weight: bold;
  font-style: normal;
}
