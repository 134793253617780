.caseStudyContainer {

}

.caseStudy {
  font-size: 17px;
  line-height: 1.5em;
}

.process {
  display: block;
  margin: 0 auto;
  max-height: 350px;
  max-width: 100%;
}

.videocontainer {
  height: 400px;
  display: flex;
  justify-content: center;
  margin-top: 60px;
}

.processvideo {
  max-height: 400px;
  border-radius: 24px;
  margin-right: 30px;
}


@media only screen and (max-width: 900px) {

  .processvideo {
    max-height: 350px;
  }
}
