
.body {
  display: flex;
  justify-content: space-between;
  max-width: 95%;
  font-weight: 'Regular';
  line-height: 1.8em;
  text-align: left;
  font-size: 22px;
}

.bio {
  min-width: 488px;
  max-width: 488px;
  margin-right: 44px;
}

.work {
  height: 650px;
  position: relative;
}

video {
  margin-top: -30px;
  height: 100%;
}

#img {
  position: absolute;
  top: 40%;
  right: 0px;
  max-width: 100%;
  max-height: 80%;
  transform: translateY(-50%);
}

.socials {
  margin-top: 60px;
  margin-bottom: 24px;
  line-height: 0.9em;
  min-width: 300px;
}

.social-link {
  margin-right: 32px;
}

@media only screen and (max-width: 900px) {

  .body {
    flex-flow: column;
    line-height: 1.8em;
    max-width: 100%;
    font-size: 17px;
  }

  .bio {
    min-width: 0px;
    margin-right: 0px;
    max-width: 100%;
  }

  .work {
    height: 450px;
    position: relative;
  }

  video {
    margin-top: 0px;
    max-width: 100%;
  }

  #img {
    position: absolute;
    top: 5%;
    left: 50%;
    max-width: 100%;
    max-height: 100%;
    transform: translateX(-50%);
  }

  #smiley, #bread {
    width: 18px;
  }
}
